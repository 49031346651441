import axios from 'axios';
import PageTitle from "../components/common/PageTitle";
import { Container, Row, Col } from "shards-react";
import Plotly from 'plotly.js';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function Analysis() {
  const [plotResponseData, setPlotResponseData] = useState(null);





  useEffect(() => {





    // Call the functions
    fetchVisualisationData()
  }, []);


  const fetchVisualisationData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/agri/ph/visualisation`);
      console.log("fetchVisualisationData", response);
      setPlotResponseData(response.data.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
     <Container fluid className="main-content-container px-4" style={{ backgroundColor: "white" }}>
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Visualisation" className="text-sm-left" />
      </Row>

      <h2>Histograms of Selected Features</h2>
      {plotResponseData ? <>
        {/* <Row noGutters className="py-4"  >
          <Col>
          <Plot
              data={[{
                x: plotResponseData.pH,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 0.1,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue', // Border color
                    width: 1, // Border width
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'pH',
                  xaxis: { title: 'pH Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 0.1,
                  bargroupgap: 0.2,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Col>
          <Col>
          <Plot
              data={[{
                x: plotResponseData.phosphorus,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 3.2,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue',
                    width: 1.5,
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'phosphorus',
                  xaxis: { title: 'phosphorus Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 1.5,
                  bargroupgap: 0.1,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Col>
        </Row> */}
        {/* <Row noGutters className="py-4"  >
          <Col>
          <Plot
              data={[{
                x: plotResponseData.potassium,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 3.2,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue', // Border color
                    width: 1.5, // Border width
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'Potassium',
                  xaxis: { title: 'potassium Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 1.5,
                  bargroupgap: 0.1,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Col>
          <Col>
          <Plot
              data={[{
                x: plotResponseData.temperature,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 1.2,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue', // Border color
                    width: 1, // Border width
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'Temperature',
                  xaxis: { title: 'temperature Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 1.5,
                  bargroupgap: 0.1,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Col>
        </Row>
        <Row noGutters className="py-4"  >
          <Col>
          <Plot
              data={[{
                x: plotResponseData.humidity,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 0.2,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue', // Border color
                    width: 1, // Border width
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'Humidity',
                  xaxis: { title: 'humidity Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 0.1,
                  bargroupgap: 0.2,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Col>
          <Col>
          <Plot
              data={[{
                x: plotResponseData.nitrogen,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 3.5,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue', // Border color
                    width: 1, // Border width
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'Nitrogen',
                  xaxis: { title: 'nitrogen Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 0.1,
                  bargroupgap: 0.2,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Col>
        </Row> */}
        <Row noGutters className="py-4"  >
          {/* <Col>
          <Plot
              data={[
                {
                  z: plotResponseData.correlation_matrix.data,
                  x: plotResponseData.correlation_matrix.columns,
                  y: plotResponseData.correlation_matrix.index,
                  type: 'heatmap',
                  colorscale: 'RdBu',
                  colorbar: {
                    title: 'Correlation',
                  },
                }
              ]}
              layout={{
                title: 'Heat Map',
                
              }}
            />
          </Col> */}
          <Col>
          <Plot
              data={[
                {
                  labels: Object.keys(plotResponseData.nutrient_means),
                  values: Object.values(plotResponseData.nutrient_means),
                  type: 'pie',
                  textinfo: 'percent+label',
                  hoverinfo: 'label+percent',
                  marker: {
                    colors: ['#FFA07A', '#20B2AA', '#FFD700']
                  }
                }
              ]}
              layout={{
                title: 'Device NPK Pie Chart',
              }}
            />
          </Col>
        </Row>
       
      </> : <div> No Data Found</div>}
         
     </Container>

    </>
  );
}

export default Analysis;
