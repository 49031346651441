import axios from 'axios';
import PageTitle from "../components/common/PageTitle";
import { Container, Row, Col } from "shards-react";
import Plotly from 'plotly.js';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function Analysis() {
  const [plotData, setPlotData] = useState(null);
  const [plotResponseData, setPlotResponseData] = useState(null);
  const [dataset, setDataSet] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });


  const columns = [
    {
      field: "id", headerName: "ID", width: 90, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'SL No'}
        </strong>
      )
    },
    {
      field: "pH", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'pH'}
        </strong>
      )
    },
    {
      field: "Anomaly Status", headerName: "ID", width: 230, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Anomaly Status'}
        </strong>
      )
    },
    {
      field: "suggestion", headerName: "ID", width: 230, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Suggestion'}
        </strong>
      )
    },
    {
      field: "timestamp", headerName: "ID", width: 180, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Timestamp'}
        </strong>
      )
    },

  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/agri/ph/analysis`
        );
        console.log({ response });
        setPlotData(response.data.data)
      } catch (error) {
        console.error('Error fetching API:', error);
      }
    };





    // Call the functions
    fetchData();
    // fetchPlotlyGraphData();
  }, []);


  const fetchDataSet = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/agri/ph/analysis/dataset?page=${paginationModel.page + 1}&page_size=${paginationModel.pageSize}`);
      setTotalRows(response.data.total)
      setDataSet(response.data.agri_data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDataSet();
  }, [paginationModel])// No dependencies to avoid unnecessary re-renders


  return (
    <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>

      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Analysis" className="text-sm-left" />
      </Row>

      {/* {plotData ? (
        <Grid container>
          <Grid item>
            <h2>pH Analysis</h2>

            <Plot
              data={[{
                x: plotData.pH,
                type: 'histogram',
                histnorm: 'probability',
                autobinx: false,
                xbins: {
                  size: 0.2,
                },
                marker: {
                  color: 'skyblue',
                  line: {
                    color: 'skyblue', // Border color
                    width: 1, // Border width
                  },
                },
                histfunc: 'count',
              },]}
              layout={
                {
                  title: 'pH Distribution',
                  xaxis: { title: 'pH Value' },
                  yaxis: { title: 'Frequency' },
                  bargap: 0.1,
                  bargroupgap: 0.2,
                  grid: { rows: 1, columns: 1, pattern: 'independent' }
                }
              }
            />
          </Grid>
          <Grid item>
            <h2>pH Category</h2>

            <Plot
              data={[
                {
                  x: Object.keys(plotData.ph_category_counts),
                  y: Object.values(plotData.ph_category_counts),
                  type: 'bar',
                  marker: {
                    color: 'lightgreen',
                    line: {
                      color: 'black',
                      width: 1
                    }
                  }
                }
              ]}
              layout={{
                title: 'pH Category Distribution',
                xaxis: { title: 'pH Category', tickangle: 335 },
                yaxis: { title: 'Frequency' },
                bargap: 0.05,
                bargroupgap: 0.2,
                grid: { rows: 1, columns: 1, pattern: 'independent' }
              }}
            />
          </Grid>
          <Grid item>
            <h2>pH Anomaly</h2>

            <Plot
              data={[
                {
                  x: Object.keys(plotData.pH_anomaly_counts),
                  y: Object.values(plotData.pH_anomaly_counts),
                  type: 'bar',
                  marker: {
                    color: 'lightgreen',
                    line: {
                      color: 'black',
                      width: 1
                    }
                  }
                }
              ]}
              layout={{
                title: 'pH Category Distribution',
                xaxis: { title: 'pH Anomaly', tickangle: 335 },
                yaxis: { title: 'Frequency' },
                bargap: 0.05,
                bargroupgap: 0.2,
                grid: { rows: 1, columns: 1, pattern: 'independent' }
              }}
            />
          </Grid>
        </Grid>
      ) : <div> Loading</div>} */}
      <div style={{ height: 600, width: "80%", marginTop: 35 }}>
        <DataGrid
          rows={dataset}
          columns={columns}
          density="compact"
          rowCount={totalRows}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
        />
      </div>
    </Container>
  );
}

export default Analysis;
