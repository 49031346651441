import { Box, Button, FormControl, InputLabel, MenuItem, Select, Slider, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";


const recommendation = () => {

    const [val, setVal] = React.useState({
        temperature: 25,
        humidity: 50,
        nitrogen: 50,
        potassium: 50,
        phosphorus: 50,
        croptype: 1,
        soiltype: 1

    });
    const [predicted, setPredictedData] = useState('')


    const handlePrediction = async () => {
         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/agri/fertilizer/recommendation`,{
            "temperature": val.temperature,
            "humidity": val.humidity,
            "nitrogen": val.nitrogen,
            "potassium": val.potassium,
            "phosphorous": val.phosphorus,
            "croptype": val.croptype,
            "soiltype": val.soiltype
         });
         if(response.data.status == "success"){
             setPredictedData(response.data.data)
         }
    }
    const handleChange = (e) => {
        setVal({ ...val, [e.target.name]: e.target.value })
    };
    return (
        <div style={{ width: '100%', marginTop: 20, marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Box sx={{ width: 650 }}>
                <Typography variant="h8">Temperature</Typography>
                <Slider
                    marks={[
                        {
                            value: -10,
                            label: '-10',
                        },
                        {
                            value: 50,
                            label: '50',
                        },
                    ]}
                    step={1}
                    value={val.temperature}
                    name="temperature"
                    valueLabelDisplay="auto"
                    min={-10}
                    max={50}
                    onChange={(e) => handleChange(e)}
                />
            </Box>
            <Box sx={{ width: 650 }}>
                <Typography variant="h8">Humidity</Typography>
                <Slider
                    marks={[
                        {
                            value: 0,
                            label: '0',
                        },
                        {
                            value: 100,
                            label: '100',
                        },
                    ]}
                    step={1}
                    value={val.humidity}
                    name="humidity"
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    onChange={(e) => handleChange(e)}
                />
            </Box>
            {/* <Box sx={{ width: 650 }}>
                <Typography variant="h8">Moisture</Typography>
                <Slider
                    marks={[
                        {
                            value: 0,
                            label: '0',
                        },
                        {
                            value: 100,
                            label: '100',
                        },
                    ]}
                    step={1}
                    value={val.moisture}
                    name="moisture"
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    onChange={(e) => handleChange(e)}
                />
            </Box> */}
            <Box sx={{ width: 650 }}>
                <Typography variant="h8">Nitrogen</Typography>
                <Slider
                    marks={[
                        {
                            value: 0,
                            label: '0',
                        },
                        {
                            value: 200,
                            label: '100',
                        },
                    ]}
                    step={1}
                    value={val.nitrogen}
                    name="nitrogen"
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    onChange={(e) => handleChange(e)}
                />
            </Box>
            <Box sx={{ width: 650 }}>
                <Typography variant="h8">Potassium</Typography>
                <Slider
                    marks={[
                        {
                            value: 0,
                            label: '0',
                        },
                        {
                            value: 200,
                            label: '200',
                        },
                    ]}
                    step={1}
                    value={val.potassium}
                    name="potassium"
                    valueLabelDisplay="auto"
                    min={0}
                    max={200}
                    onChange={(e) => handleChange(e)}
                />
            </Box>
            <Box sx={{ width: 650 }}>
                <Typography variant="h8">Phosphorus</Typography>
                <Slider
                    marks={[
                        {
                            value: 0,
                            label: '0',
                        },
                        {
                            value: 200,
                            label: '200',
                        },
                    ]}
                    step={1}
                    value={val.phosphorus}
                    valueLabelDisplay="auto"
                    name="phosphorus"
                    min={0}
                    max={200}
                    onChange={(e) => handleChange(e)}
                />
            </Box>
            <Box sx={{ width: 650 }}>
                <FormControl
              margin="normal"
              variant="outlined"
              fullWidth={true}
              sx={{ gap: 6 }}
            >

              <InputLabel id="demo-select-small-label" >Soil Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={val.soiltype}
                name="soiltype"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value='1'>Loamy</MenuItem>
                <MenuItem value='0'>Sandy</MenuItem>
                <MenuItem value='4'>Clayey</MenuItem>
                <MenuItem value='2'>Black</MenuItem>
                <MenuItem value='3'>Red</MenuItem>
              </Select>
           
            </FormControl>
            </Box>
            <Box sx={{ width: 650 }}>
            <FormControl
              margin="normal"
              variant="outlined"
              fullWidth={true}
              sx={{ gap: 6 }}
            >

              <InputLabel id="demo-select-small-label" >Crop Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={val.croptype}
                name="croptype"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value='1'>Sugarcane</MenuItem>
                <MenuItem value='2'>Cotton</MenuItem>
                <MenuItem value='7'>Millets</MenuItem>
                <MenuItem value='4'>Paddy</MenuItem>
                <MenuItem value='9'>Pulses</MenuItem>
                <MenuItem value='6'>Wheat</MenuItem>
                <MenuItem value='3'>Tobacco</MenuItem>
                <MenuItem value='5'>Barley</MenuItem>
                <MenuItem value='8'>Oil seeds</MenuItem>
                <MenuItem value='10'>Ground Nuts</MenuItem>
                <MenuItem value='0'>Maize</MenuItem>
              </Select>

            </FormControl>

            </Box>
            <Button variant="contained" onClick={handlePrediction}>
                Predict
              </Button>
             {
                predicted && <div style={{ fontSize: 35, fontWeight: 'bolder'}}>N-P-K : {predicted}</div>
             }

        </div>
    )
}

export default recommendation