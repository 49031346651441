import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import React, { useState } from "react"
import { Col, Container, Row } from "shards-react"
import PageTitle from "../components/common/PageTitle"
import axios from "axios"
import Plot from "react-plotly.js"
import SoyabeanDiseaseDetection from "./SoyabeanDiseaseDetection"
import TomatoDiseaseDetection from "./TomatoDiseaseDetection"
const DiseaseDetection = () => {
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [crop, setSelectedCrop] = useState("soyabean");
    const [predictDisease, setPredictDisease] = useState();
    const [model, setModel] = useState("tomato_leaf_disease_model_VGG16.h5");
    
    let crops = [
        {
            id: 1,
            name: "soyabean"
        },
        {
            id: 2,
            name: "Tomato"
        },
    ];

    const handleInputChange = e => {
        setImagePreview(null)
        setPredictDisease()
        setSelectedCrop(e.target.value);

    };
    const handleChange = async (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        setFile(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }

        if (crop == 'soyabean') {

            try {

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get/soyabean/disease/prediction`, formData, {
                    'Content-Type': 'multipart/form-data'
                }
                );
                if (response.data.status == 'success') {
                    setPredictDisease(response.data)
                }

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }

        else {
            formData.append('model', model);
            try {

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get/tomato/disease/prediction`, formData, {
                    'Content-Type': 'multipart/form-data'
                }
                );
                if (response.data.status == 'success') {
                    setPredictDisease(response.data)
                }

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }

    }
    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
            <Row noGutters className="page-header py-2">
                {/* <PageTitle sm="4" title="SoyaBean" subtitle="" className="text-sm-left" /> */}
                <Col>
                    <FormControl style={{ marginTop: "12px" }}>
                        <InputLabel id="asset-select-label">Crop Type</InputLabel>

                        <Select
                            labelId="asset-select-label"
                            id="asset-select"
                            name="cropType"
                            value={crop}
                            onChange={e => handleInputChange(e)}
                            label="Select category"
                            style={{ width: 165 }}
                        >
                            {crops.map(crop => {
                                return (
                                    <MenuItem key={crop.id} value={crop.name}>
                                        {crop.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Row noGutters style={{ fontSize: '20px' }} className="page-header py-1">
                <PageTitle sm="4" title={`${crop} Leaf Disease Detection`} subtitle="" className="text-sm-left" />
            </Row>
            <Row noGutters className="py-3" style={{ fontSize: '13px' }}>Upload an image of a {crop} leaf to predict its disease.</Row>

            {
                crop == 'soyabean' && <SoyabeanDiseaseDetection predictDisease={predictDisease} imagePreview={imagePreview} handleChange={handleChange} />
            }
            {
                crop == 'Tomato' && <TomatoDiseaseDetection predictDisease={predictDisease} imagePreview={imagePreview} handleChange={handleChange} model={model} setModel={setModel} />
            }

        </Container>
    )
}

export default DiseaseDetection