import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "shards-react";
import Chart from "../../utils/chart";
import { fetchData } from "../../api/AgriApi";
import { format } from "date-fns";

class UsersOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      error: null,
      loading: false
    };

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.fetchDataFromAPI();
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    if (
      prevProps.startDate !== startDate ||
      prevProps.endDate !== endDate ||
      prevProps.chartData !== this.props.chartData
    ) {
      this.fetchDataFromAPI();
    }
  }

  fetchDataFromAPI = async () => {
    try {
      this.setState({ loading: true });
      let startDates = this.props.startDate;
      let endDates = this.props.endDate;
      
      const response = await fetchData(startDates, endDates);
      // const response = await fetchData();

      const originalNitrogenValues = response.data.map(
        item => item.temperature
      );
      const originalPotassiumValues = response.data.map(item => item.humidity);
      const originalPhValues = response.data.map(item => item.ph);
      const originalConductivityValues = response.data.map(
        item => item.conductivity
      );

      const istTimes = response.data.map(timeString => {
        const utcTime = new Date(timeString);
        const istTime = utcTime.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata"
        });
        return istTime;
      });

      const { startDate, endDate } = this.props;
      function getNextDate(dateString) {
        const currentDate = new Date(dateString);
        const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

        const year = nextDate.getFullYear();
        const month = String(nextDate.getMonth() + 1).padStart(2, "0");
        const day = String(nextDate.getDate()).padStart(2, "0");

        const nextDateString = `${year}-${month}-${day}`;
        return nextDateString;
      }

      let labels = response.data.map(item => item.timestamp.slice(0, 19));
      let nitrogenValues = [...originalNitrogenValues];
      let potassiumValues = [...originalPotassiumValues];
      let phValues = [...originalPhValues];
      let conductivityValues = [...originalConductivityValues];

      if (startDate && endDate) {
        const startDateFormatted = format(new Date(startDate), "yyyy-MM-dd");
        const endDateFormatted = format(new Date(endDate), "yyyy-MM-dd");

        let startIndex = labels.findIndex(date =>
          date.includes(startDateFormatted)
        );
        if (startIndex === -1) {
          const closestStartDate = labels.reduce((a, b) => {
            const aDate = new Date(a);
            const bDate = new Date(b);
            return Math.abs(aDate - startDate) < Math.abs(bDate - startDate)
              ? a
              : b;
          });
          startIndex = labels.findIndex(date =>
            date.includes(closestStartDate)
          );
        }
        let endIndex = labels.findIndex(date =>
          date.includes(getNextDate(endDateFormatted))
        );
        if (endIndex === -1) {
          const closestEndDate = labels.reduce((a, b) => {
            const aDate = new Date(a);
            const bDate = new Date(b);
            return Math.abs(aDate - endDate) < Math.abs(bDate - endDate)
              ? a
              : b;
          });
          endIndex = labels.findIndex(date => date.includes(closestEndDate));
        }

        labels = labels.slice(startIndex, endIndex + 1);
        nitrogenValues = nitrogenValues.slice(startIndex, endIndex + 1);
        potassiumValues = potassiumValues.slice(startIndex, endIndex + 1);
        phValues = phValues.slice(startIndex, endIndex + 1);
        conductivityValues = conductivityValues.slice(startIndex, endIndex + 1);
      } else if (startDate && !endDate) {
        const startDateFormatted = format(new Date(startDate), "yyyy-MM-dd");

        let startIndex = labels.findIndex(date =>
          date.includes(startDateFormatted)
        );
        if (startIndex === -1) {
          const closestStartDate = labels.reduce((a, b) => {
            const aDate = new Date(a);
            const bDate = new Date(b);
            return Math.abs(aDate - startDate) < Math.abs(bDate - startDate)
              ? a
              : b;
          });
          startIndex = labels.findIndex(date =>
            date.includes(closestStartDate)
          );
        }

        labels = labels.slice(startIndex);
        nitrogenValues = nitrogenValues.slice(startIndex);
        potassiumValues = potassiumValues.slice(startIndex);
        phValues = phValues.slice(startIndex);
        conductivityValues = conductivityValues.slice(startIndex);
      }

      const hasData =
        nitrogenValues.length > 0 &&
        potassiumValues.length > 0 &&
        phValues.length > 0 &&
        conductivityValues.length > 0;

      const updatedChartData = {
        labels: labels,
        datasets: [
          {
            ...this.props.chartData.datasets[0],
            data: hasData ? nitrogenValues : [0]
          },
          {
            ...this.props.chartData.datasets[1],
            data: hasData ? potassiumValues : [0]
          },
          {
            ...this.props.chartData.datasets[2],
            data: hasData ? phValues : [0]
          },
          {
            ...this.props.chartData.datasets[3],
            data: hasData ? conductivityValues : [0]
          }
        ]
      };
      console.log(updatedChartData);

      this.setState({ data: updatedChartData, loading: false }, () => {
        this.renderChart();
      });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  renderChart = () => {
    const { data } = this.state;
    if (!data) {
      return; // Exit early if data is null
    }
    const { chartOptions } = this.props;

    const chartOptionsWithDefaults = {
      responsive: true,
      legend: {
        position: "top"
      },
      elements: {
        line: {
          tension: 0.3
        },
        point: {
          radius: 0
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: false,
            ticks: {
              callback(tick, index) {
                return index % 7 !== 0 ? "" : tick;
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              suggestedMax: 30,
              callback(tick) {
                if (tick === 0) {
                  return tick;
                }
                return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
              }
            }
          }
        ]
      },
      hover: {
        mode: "nearest",
        intersect: false
      },
      tooltips: {
        custom: false,
        mode: "nearest",
        intersect: false
      },
      ...chartOptions
    };

    const chart = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: data,
      options: chartOptionsWithDefaults
    });

    const buoMeta = chart.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[data.datasets[0].data.length - 1]._model.radius = 0;

    chart.render();
  };

  render() {
    const { title } = this.props;

    return (
      <Card small className="h-100" >
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          {this.state.loading ? (
            <div>Loading chart data...</div>
          ) : this.state.error ? (
            <div>Error: {this.state.error}</div>
          ) : (
            <canvas
              height="120"
              ref={this.canvasRef}
              style={{ maxWidth: "100% !important" }}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

UsersOverview.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.object,
  chartOptions: PropTypes.object
};

UsersOverview.defaultProps = {
  title: "Temperature, Humidity, pH & Conductivity",
  chartData: {
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "Temperature",
        fill: "start",
        data: [],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Humidity",
        fill: "start",
        data: [],
        backgroundColor: "rgba(0,184,216,0.1)",
        borderColor: "rgba(0,184,216,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(0,184,216,1)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "pH",
        fill: "start",
        data: [],
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "conductivity",
        fill: "start",
        data: [],
        backgroundColor: "rgba(23,89, 74, 0.1)",
        borderColor: "rgba(23,89, 74, 1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(23,89, 74,1)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      }
    ]
  },
  chartOptions: {}
};

export default UsersOverview;
