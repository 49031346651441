import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import DeficiencyGraph from "../components/components-overview/DeficiencyGraph";
import RecommendationForm from "../components/components-overview/FertilizerRecommendations";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Plot from "react-plotly.js";

const soilDataAnalysis = () => {
  const [analysisdata, setAnalysisData] = useState({})

  function colorCode(val) {
    let color;
    if (['Low', 'Acidic', 'Low Conductivity', 'Critical for Germination', 'Injurious to most crops'].includes(val)) {
      color = 'red';
    } else if (['High', 'Optimal', 'Normal Conductivity'].includes(val)) {
      color = 'green';
    } else {
      color = 'yellow';
    }
    return color;
  }
  function convertUTCToIST(utcTime) {

    // Create a Date object using the UTC time string
    // let date = utcTime;

    // Get the UTC time in milliseconds and add the IST offset (5 hours 30 minutes)
    // let istOffset =  5 * 60 * 60 * 1000 + 30 * 60 * 1000; // IST is UTC + 5:30
    // let istTime = new Date(new Date(date).getTime() + istOffset).toLocaleString();
    // console.log(istTime);

    // // Format the IST time
    // let hours = istTime.getHours().toString().padStart(2, '0');
    // let minutes = istTime.getMinutes().toString().padStart(2, '0');
    // let seconds = istTime.getSeconds().toString().padStart(2, '0');
    // let milliseconds = istTime.getMilliseconds().toString().padStart(3, '0');

    return utcTime;
  }
  const columns = [
    {
      headerName: "ID", width: 90, headerAlign: 'center', align: "center",
      filterable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
      renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'SL No'}
        </strong>
      )
    },
    {
      field: "date", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Date'}
        </strong>
      )
    },
    {
      field: "time", headerName: "ID", width: 180, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Time'}
        </strong>
      ),
      renderCell: (params) => {
        let istTime = convertUTCToIST(params.row['time']);
        return (
          <div
          >
            {params.row['time']}
          </div>
        )
      }
    },
    {
      field: "day", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Day'}
        </strong>
      )
    },
    {
      field: "nitrogen", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Nitrogen'}
        </strong>
      )
    },
    {
      field: "Nitrogen Category", headerName: "ID", width: 150, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Nitrogen Category'}
        </strong>
      ),
      renderCell: (params) => {
        // Determine if this cell should be colored
        let shouldColorCell = colorCode(params.row['Nitrogen Category']);

        return (
          <div
            style={{
              backgroundColor: shouldColorCell,
              // color: shouldColorCell ? 'black' : 'inherit',
              padding: '12px 20px',
            }}
          >
            {params.row['Nitrogen Category']}
          </div>
        );
      },

    },
    {
      field: "Nitrogen Suggestion", headerName: "ID", width: 300, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Nitrogen Suggestion'}
        </strong>
      )
    },
    {
      field: "phosphorus", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Phosphorus'}
        </strong>
      ),

    },
    {
      field: "Phosphorus Category", headerName: "ID", width: 150, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Phosphorus Category'}
        </strong>
      ),
      renderCell: (params) => {
        // Determine if this cell should be colored
        let shouldColorCell = colorCode(params.row['Phosphorus Category']);

        return (
          <div
            style={{
              backgroundColor: shouldColorCell,
              padding: '12px 20px',
            }}
          >
            {params.row['Phosphorus Category']}
          </div>
        );
      },

    },
    {
      field: "Phosphorus Suggestion", headerName: "ID", width: 300, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Category Suggestion'}
        </strong>
      )
    },
    {
      field: "potassium", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Potassium'}
        </strong>
      )
    },
    {
      field: "Potassium Category", headerName: "ID", width: 150, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Potassium Category'}
        </strong>
      ),
      renderCell: (params) => {
        // Determine if this cell should be colored
        let shouldColorCell = colorCode(params.row['Potassium Category']);

        return (
          <div
            style={{
              backgroundColor: shouldColorCell,
              padding: '12px 20px',
            }}
          >
            {params.row['Potassium Category']}
          </div>
        );
      },

    },
    {
      field: "Potassium Suggestion", headerName: "ID", width: 300, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Category Suggestion'}
        </strong>
      )
    },
    {
      field: "ph", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'ph'}
        </strong>
      )
    },
    {
      field: "pH Category", headerName: "ID", width: 150, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'ph Category'}
        </strong>
      ),
      renderCell: (params) => {
        // Determine if this cell should be colored
        let shouldColorCell = colorCode(params.row['pH Category']);

        return (
          <div
            style={{
              backgroundColor: shouldColorCell,
              padding: '12px 20px',
            }}
          >
            {params.row['pH Category']}
          </div>
        );
      },

    },
    {
      field: "pH Suggestion", headerName: "ID", width: 300, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'ph Suggestion'}
        </strong>
      )
    },
    {
      field: "conductivity", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Conductivity'}
        </strong>
      )
    },
    {
      field: "Conductivity Category", headerName: "ID", width: 150, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Conductivity Category'}
        </strong>
      ),
      renderCell: (params) => {
        // Determine if this cell should be colored
        let shouldColorCell = colorCode(params.row['Conductivity Category']);

        return (
          <div
            style={{
              backgroundColor: shouldColorCell,
              padding: '12px 20px',
            }}
          >
            {params.row['Conductivity Category']}
          </div>
        );
      },
    },
    {
      field: "Conductivity Suggestion", headerName: "ID", width: 300, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Conductivity Suggestion'}
        </strong>
      )
    },
    {
      field: "Soil Fertility", headerName: "ID", width: 120, headerAlign: 'center', align: "center", renderHeader: () => (
        <strong style={{ color: '#334474' }}>
          {'Soil Fertility'}
        </strong>
      )
    },

  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get/soil/data/analysis`
        );
        setAnalysisData(response.data)
      } catch (error) {
        console.error('Error fetching API:', error);
      }
    };





    // Call the functions
    fetchData();
    // fetchPlotlyGraphData();
  }, []);

  const isDataGridReady =
    analysisdata &&
    analysisdata.combined_df &&
    Array.isArray(analysisdata.combined_df);

  const isPlotDataReady =
    analysisdata &&
    analysisdata.df_nitrogen &&
    analysisdata.df_phosphorus &&
    analysisdata.df_potassium && analysisdata.df_ph && analysisdata.df_conductivity;

  let nutrientMeans = {}
  if (isPlotDataReady) {
    nutrientMeans = {
      nitrogen: Object.values(analysisdata.df_nitrogen).reduce((a, b) => a + b, 0) / Object.keys(analysisdata.df_nitrogen).length,
      phosphorus: Object.values(analysisdata.df_phosphorus).reduce((a, b) => a + b, 0) / Object.keys(analysisdata.df_phosphorus).length,
      potassium: Object.values(analysisdata.df_potassium).reduce((a, b) => a + b, 0) / Object.keys(analysisdata.df_potassium).length,
    };

  }
  return (
    <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: "white" }}>
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Soil Data Analysis" subtitle="" className="text-sm-left" />
      </Row>
      <Container fluid className="px-2 h-500">
        <Row noGutters className="page-header py-4">
          <div style={{ height: 600, width: '100%' }}>
            {isDataGridReady ? (
              <DataGrid
                getRowId={(data) => data.date + data.time}
                rows={analysisdata.combined_df}
                columns={columns}
                density="compact"
                paginationMode="server"
              />
            ) : (
              <div>Loading data grid...</div>
            )}
          </div>
        </Row>
        <Row noGutters className="page-header py-4">
          {isPlotDataReady ? (
            <>
              <Plot
                data={[
                  {
                    x: Object.values(analysisdata.df_nitrogen),
                    type: 'histogram',
                    name: 'Nitrogen',
                    marker: { color: '#1f77b4' },
                  },
                ]}
                layout={{
                  title: 'Histogram of Nitrogen',
                  xaxis: { title: 'Nitrogen Value' },
                  yaxis: { title: 'Frequency' },
                  width: 600,
                  height: 400,
                }}
                config={{ responsive: true }}
              />

              <Plot
                data={[
                  {
                    x: Object.values(analysisdata.df_phosphorus),
                    type: 'histogram',
                    name: 'Phosphorus',
                    marker: { color: '#ff7f0e' },
                  },
                ]}
                layout={{
                  title: 'Histogram of Phosphorus',
                  xaxis: { title: 'Phosphorus Value' },
                  yaxis: { title: 'Frequency' },
                  width: 600,
                  height: 400,
                }}
                config={{ responsive: true }}
              />
              <Plot
                data={[
                  {
                    x: Object.values(analysisdata.df_potassium),
                    type: 'histogram',
                    name: 'Potassium',
                    marker: { color: '#2ca02c' },
                  },
                ]}
                layout={{
                  title: 'Histogram of Potassium',
                  xaxis: { title: 'Potassium Value' },
                  yaxis: { title: 'Frequency' },
                  width: 600,
                  height: 400,
                }}
                config={{ responsive: true }}
              />
              {/* <Plot
              data={[
                {
                  x: Object.values(analysisdata.df_nitrogen),
                  type: 'histogram',
                  name: 'Nitrogen',
                  marker: { color: '#1f77b4' },
                },
                {
                  x: Object.values(analysisdata.df_phosphorus),
                  type: 'histogram',
                  name: 'Phosphorus',
                  marker: { color: '#ff7f0e' },
                },
                {
                  x: Object.values(analysisdata.df_potassium),
                  type: 'histogram',
                  name: 'Potassium',
                  marker: { color: '#2ca02c' },
                },
              ]}
              layout={{
                barmode: 'overlay',
                title: 'Histograms of NPK',
                xaxis: { title: 'Value' },
                yaxis: { title: 'Frequency' },
                bargap: 0.2,
                bargroupgap: 0.1,
                width: 900,
                height: 500,
              }}
              config={{ responsive: true }}
            /> */}
            </>

          ) : (
            <div>Loading plot...</div>
          )}
        </Row>
        <Row noGutters className="page-header py-4">
          {isPlotDataReady ? (
            <Plot
              data={[
                {
                  values: Object.values(nutrientMeans),
                  labels: Object.keys(nutrientMeans),
                  type: 'pie',
                  textinfo: 'label+percent',
                  textposition: 'inside',
                  hole: 0.4,
                  marker: {
                    colors: ['#1f77b4', '#ff7f0e', '#2ca02c'],
                  },
                },
              ]}
              layout={{
                title: 'Nutrient Composition',
                height: 600,
                width: 600,
                showlegend: true,
              }}
              config={{ responsive: true }}
            />

          ) : (
            <div>Loading plot...</div>
          )}
        </Row>
        <Row noGutters className="page-header py-4">
          {isPlotDataReady ? (
            <Plot
              data={[
                {
                  x: Object.values(analysisdata.df_ph),
                  type: 'histogram',
                  nbinsx: 20,
                  marker: { color: 'blue' },
                  opacity: 0.7,
                },

              ]}
              layout={{
                title: 'pH Levels Distribution',
                xaxis: { title: 'pH' },
                yaxis: { title: 'Frequency' },
                bargap: 0.05,
                width: 800,
                height: 600,
              }}
            />

          ) : (
            <div>Loading plot...</div>
          )}
        </Row>
        <Row noGutters className="page-header py-4">
          {isPlotDataReady ? (
            <Plot
              data={[
                {
                  x: Object.values(analysisdata.df_conductivity),
                  type: 'histogram',
                  nbinsx: 20,
                  marker: { color: 'blue' },
                  opacity: 0.7,
                },

              ]}
              layout={{
                title: 'Conductivity Levels Distribution',
                xaxis: { title: 'conductivity' },
                yaxis: { title: 'Count' },
                bargap: 0.05,
                width: 800,
                height: 600,
              }}
            />

          ) : (
            <div>Loading plot...</div>
          )}
        </Row>
      </Container>

    </Container>
  );
}


export default soilDataAnalysis;