import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import SmallStats from "../common/SmallStats";
import UsersOverview from "./UsersOverview";
import UsersByDevice from "./UsersByDevice";
import RangeDatePicker from "../common/RangeDatePicker";
import pHGraphsHisto from "./pHGraphsHisto";
import axios from "axios";

class Test1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: 0,
      Humidity: 0,
      conductivity: 0,
      ph: 0,
      nitrogen: 0,
      phosphorus: 0,
      pottasium: 0,
      chartData: null
    };
  }

  componentDidMount() {
    this.fetchApiData();
    this.fetchChartData();
  }

  fetchApiData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/agri/latest/data`);
      const apiData = await response.json();
      const a = apiData.data[0];
      this.setState({
        temp: a.temperature,
        Humidity: a.humidity,
        conductivity: a.conductivity,
        ph: a.ph,
        nitrogen: a.nitrogen,
        phosphorus: a.phosphorus,
        pottasium: a.potassium
      });
    } catch (error) {
      console.log("Error fetching API data:", error);
    }
  };

  fetchChartData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/ai/ph_graph`);
      this.setState({ chartData: response.data.chart_data });
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };

  render() {
    const {
      temp,
      Humidity,
      conductivity,
      ph,
      nitrogen,
      phosphorus,
      pottasium,
      chartData
    } = this.state;

    const smallStat = [
      {
        label: "Nitrogen ",
        
        value: [<span style={{fontSize:"	1.625rem"}}>{nitrogen}</span>, <span className="small-unit" style={{fontSize:"	1rem"}}>mg/kg</span>],
        percentage: "2.4% ",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
         
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            data: [3, 2, 3, 2, 4, 5, 4]
          }
        ]
      },
      {
        label: "Phosphorus",
        value: [<span style={{fontSize:"	1.625rem"}}>{phosphorus}</span>, <span className="small-unit" style={{fontSize:"	1rem"}}>mg/kg</span>],
        percentage: "12.4",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        style: { fontSize: '12px' },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: [1, 2, 3, 3, 3, 4, 4]
          }
        ]
      },
      {
        label: "Potassium",
        value: [<span style={{fontSize:"	1.625rem"}}>{pottasium}</span>, <span className="small-unit" style={{fontSize:"	1rem"}}>mg/kg</span>],
        percentage: "12.4",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        style: { fontSize: '12px' },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: [1, 2, 3, 3, 3, 4, 4]
          }
        ]
      },
      {
        label: "Temperature",
        value: [<span style={{fontSize:"	1.625rem"}}>{temp}</span>, <span className="small-unit" style={{fontSize:"1rem"}}>°C</span>],
        percentage: "4.7%",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: [1, 2, 1, 3, 5, 4, 7]
          }
        ]
      },
      {
        label: "Humidity",
        value: [<span style={{fontSize:"	1.625rem"}}>{Humidity}</span>, <span className="small-unit" style={{fontSize:"	1rem"}}> % RH</span>],
        percentage: "12.4",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: [1, 2, 3, 3, 3, 4, 4]
          }
        ]
      },
      {
        label: "Conductivity",
        value: [<span style={{fontSize:"	1.625rem"}}>{conductivity}</span>, <span className="small-unit" style={{fontSize:"1rem"}}>us/cm</span>],
        percentage: "3.8%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,180,0,0.1)",
            borderColor: "rgb(255,180,0)",
            data: [2, 3, 3, 3, 4, 3, 3]
          }
        ]
      },
      {
        label: "pH",
        value: [<span style={{fontSize:"	1.625rem"}}>{ph}</span>, <span className="small-unit" style={{fontSize:"1rem"}}>pH</span>],
        percentage: "2.71%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,65,105,0.1)",
            borderColor: "rgb(255,65,105)",
            data: [1, 7, 1, 3, 1, 4, 8]
          }
        ]
      }
    ];

    return (
      <>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            {/* PageTitle */}
          </Row>

          {/* Small Stats Blocks */}
          <Row>
            {smallStat.map((stats, idx) => (
              <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>
            ))}
          </Row>
          <br />

          {/* <h1>this and that </h1> */}
          {/* {chartData && (
            <>
              <h2>pH Distribution Plot</h2>
              <img
                src={`data:image/png;base64,${chartData.distribution_plot_base64}`}
                alt="pH Distribution Plot"
              />

              <h2>pH Category Distribution Plot</h2>
              <img
                src={`data:image/png;base64,${chartData.category_distribution_plot_base64}`}
                alt="pH Category Distribution Plot"
              />
            </>
          )} */}

          <RangeDatePicker />
          <pHGraphsHisto />
        
        </Container>
      </>
    );
  }
}

export default Test1;
