import React, { useState, useEffect } from "react";
import axios from "axios";

function pHGraphsHisto() {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your/api/endpoint' with the actual endpoint URL of your API
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/ai/ph_graph`
        );
        setChartData(response.data.chart_data);
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <h1>this and that </h1>
      {chartData && (
        <div>
          {/* Display pH Distribution Plot */}
          <h2>pH Distribution Plot</h2>
          <img
            src={`data:image/png;base64,${chartData.distribution_plot_base64}`}
            alt="pH Distribution Plot"
          />

          {/* Display pH Category Distribution Plot */}
          <h2>pH Category Distribution Plot</h2>
          <img
            src={`data:image/png;base64,${chartData.category_distribution_plot_base64}`}
            alt="pH Category Distribution Plot"
          />
        </div>
      )}
    </>
  );
}

export default pHGraphsHisto;
