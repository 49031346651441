import { Button } from "@mui/material"
import axios from "axios";
import React, { useState } from "react"
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const soilTypeDetector = () => {

    const [imagePreview, setImagePreview] = useState(null);
    const [prediction, setImagePrediction] = useState(null);

    const handleChange = async (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }


        try {

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get/soil/type/predict`, formData, {
                'Content-Type': 'multipart/form-data'
            }
            );
            if (response.data.status == 'success') {
                setImagePrediction(response.data)
            }

        } catch (error) {
            console.error('Error uploading file:', error);
        }



    }


    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Soil Type Detection" className="text-sm-left" />
            </Row>
            <Row noGutters className="page-header py-2">
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleChange}
                    />
                </Button>

            </Row>
            <Row noGutters className="py-3" style={{ width: '60%' }}>
                {imagePreview && (
                    <div>
                        <img src={imagePreview} alt="Selected" style={{ marginTop: '10px', maxWidth: '100%' }} />
                    </div>
                )}
            </Row>
            {
                prediction ? 
                <>
                <Row noGutters className="py-3" style={{ width: '60%' }}>
                    <span>Predicted Soil Type: <span>{prediction.soil_type}</span></span>
                </Row>
                <Row noGutters style={{ width: '60%' }}>
                    <span>Suitable Crops:</span>
                </Row>
                <Row noGutters style={{ width: '60%' }}>
                    {
                         prediction.crops.map((crop, index)=> {
                            return <span index={index}>{crop},</span>
                         })
                    }
                </Row>
                <Row noGutters className="py-3" style={{ width: '60%' }}>
                    <span>States Where {prediction.soil_type} is Found: </span>
                </Row>
                <Row noGutters style={{ width: '60%' }}>
                    <span>{prediction.state_info}</span>
                </Row>
                </> : <>Invalid Image</>
            }


        </Container>
    )
}

export default soilTypeDetector