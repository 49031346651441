import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import Plot from "react-plotly.js";
import { Col, Row } from "shards-react";

const TomatoDiseaseDetection = ({ predictDisease, imagePreview, handleChange, model, setModel }) => {
    // const [model, setModel] = useState("tomato_leaf_disease_model_vgg16.h5");

    let models = [
        {
            id: 1,
            name: "VGG16",
            value: 'tomato_leaf_disease_model_VGG16.h5'
        },
        {
            id: 2,
            name: "ResNet50",
            value: 'tomato_leaf_disease_model_ResNet50.h5'
        },
        {
            id: 3,
            name: "InceptionV3",
            value: 'tomato_leaf_disease_model_InceptionV3.h5'
        },
        {
            id: 4,
            name: "EfficientNet",
            value: 'tomato_leaf_disease_model_EfficientNetB0.h5'
        },
    ];

    const handleInputChange = e => {
        setModel(e.target.value);
    };

    return (
        <>
            <Row noGutters className="page-header py-2" style={{ backgroundColor: "white" }}>
                {/* <PageTitle sm="4" title="SoyaBean" subtitle="" className="text-sm-left" /> */}
                <Col>
                    <FormControl style={{ marginTop: "12px" }}>
                        <InputLabel id="asset-select-label">ModelType</InputLabel>

                        <Select
                            labelId="asset-select-label"
                            id="asset-select"
                            name="ModelType"
                            value={model}
                            onChange={e => handleInputChange(e)}
                            label="Select category"
                            style={{ width: 165 }}
                        >
                            {models.map(model => {
                                return (
                                    <MenuItem key={model.id} value={model.value}>
                                        {model.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Button
                variant="contained"
                component="label"

            >
                Upload
                <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleChange}
                />
            </Button>
            <Row noGutters className="py-3" style={{ width: '50%' }}>
                {imagePreview && (
                    <div>
                        <img src={imagePreview} alt="Selected" style={{ marginTop: '10px'}} />
                    </div>
                )}
            </Row>

            {
                predictDisease && (
                    <>
                                <Row noGutters className="py-2">
                 Prediction: {predictDisease.predicted_class} with {predictDisease.confidence}% confidence
            </Row>
                    <Row noGutters className="py-4">
                    <Plot
                        data={[
                            {
                                type: 'bar',
                                x: predictDisease.predictions[0].map(p => p),
                                y: Object.values(predictDisease.class_labels),
                                orientation: 'h',
                            },
                        ]}
                        layout={ {
                            title: 'Class Probabilities',
                            xaxis: {
                                title: 'Probability',
                            },
                            yaxis: {
                                automargin: true,
                            },
                        }}
                    />
                </Row>
</>
                )}

        </>
    )
}


export default TomatoDiseaseDetection