import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Test1 from "../components/blog/Test1";

const Test = () => (
  <>
  <Test1 />
  </>
);

export default Test;
