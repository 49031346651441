import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const DeficiencyGraph = () => {
  const [nutrientSummary, setNutrientSummary] = useState([]);
  const [selectedRatio, setSelectedRatio] = useState('4:2:1'); // Default ratio

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [selectedNRatio, selectedPRatio, selectedKRatio] = selectedRatio.split(':');
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/ai/npk_ratio?n_ratio=${selectedNRatio}&p_ratio=${selectedPRatio}&k_ratio=${selectedKRatio}`
        );
        setNutrientSummary(response.data.nutrient_summary);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedRatio]);

  const handleRatioChange = (event) => {
    setSelectedRatio(event.target.value);
  };

  console.log('nutrientSummary:', nutrientSummary); // Log nutrientSummary for debugging

  const data = {
    labels: nutrientSummary.map((_, index) => `Sample ${index + 1}`),
    datasets: [
      {
        label: 'Actual Nitrogen',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
        hoverBorderColor: 'rgba(54, 162, 235, 1)',
        data: nutrientSummary.map((item) => item.nitrogen),
      },
      {
        label: 'Desired Nitrogen',
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: nutrientSummary.map((item) => item.desired_nitrogen),
      },
      {
        label: 'Actual Phosphorus',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
        hoverBorderColor: 'rgba(255, 159, 64, 1)',
        data: nutrientSummary.map((item) => item.phosphorus),
      },
      {
        label: 'Desired Phosphorus',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: nutrientSummary.map((item) => item.desired_phosphorus),
      },
      {
        label: 'Actual Potassium',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
        hoverBorderColor: 'rgba(153, 102, 255, 1)',
        data: nutrientSummary.map((item) => item.potassium),
      },
      {
        label: 'Desired Potassium',
        backgroundColor: 'rgba(255,206,86,0.2)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,206,86,0.4)',
        hoverBorderColor: 'rgba(255,206,86,1)',
        data: nutrientSummary.map((item) => item.desired_potassium),
      },
    ],
  };

  const options = {
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        beginAtZero: false,
        ticks: {
          min: -2,
        },
      },
    },
  };

  const recommendationContent = `
  Agroclimatic Zone-Based Recommendations:
  Western Himalayas (J & K, H.P.): Opt for Rice (K) with NPK ratio 4:2:1, fertilise with 120-60-30 kg/ha. Alternatively, consider Wheat with a ratio of 6:2:1 and 150-50-25 kg/ha.
  Eastern Himalaya (Assam, W.B., NE states): Choose Rice (K) at 4:2:2 with 80-40-40 kg/ha, or Rice (R) with the same ratio.
  Lower Gangetic Plain (W.B.): Rice (K) with 4:2:2 and 80-40-40 kg/ha, or Wheat with 6:2:2 and 120-40-40 kg/ha.
  Middle Gangetic Plain (Eastern U.P.):
  Rice (K) at 4:2:2 with 120-60-60 kg/ha.
  Wheat at 4:2:1.3 with 120-60-40 kg/ha.
  Sugarcane at 4.5:1.5:1 with 180-60-40 kg/ha.
  Bihar Recommendations:
  Rice (K) at 6:2:1 ratio.
  Wheat at 6:3:1 ratio.
  Sugarcane at 5:2.8:2 ratio.
  Upper Gangetic Plain (Western U.P.):
  Rice (K) with 5:2:2 and 150-60-60 kg/ha.
  Wheat at 5:2:2 and 150-60-60 kg/ha.
  Sugarcane at 5:2:2 and 150-60-60 kg/ha.
  Potato at 6:2:3 with 180-60-90 kg/ha.
  Trans Gangetic Plain (Punjab):
  Rice (K) with 4:1:1 and 120-30-30 kg/ha.
  Wheat at 4:2:1 with 120-60-30 kg/ha.
  Sugarcane at 5:2:2 and 150-60-60 kg/ha.
  Cotton (Hybrid/Bt) at 3:1:0 with 150-50-0 kg/ha.
  Gossypium hirsutum at 2.5:1:0.
  Eastern Plateau & Hills:
  Chhattisgarh: Opt for Rice (K) with 4:2:1-3 ratio.
  Pulses (K) with 1:3:1 ratio.
  Jharkhand:
  Maize (K) at 4:2:1 ratio.
  Wheat at 5:2:1 ratio.
  Eastern Maharashtra (Bhandara region):
  Rice with 4:2:1.3 ratio.
  Central Plateau & Hills (Vidarbha, Central M.P., N.E. Maharashtra, S. Gujarat, N.E. Karnataka, Northern Andhra Pradesh):
  Cotton with 2.5:1:0 ratio and 150-60-0 kg/ha.
  Maize at 4:2:1 ratio.
  Overall Recommendations:
  Consider specific crops based on agro climatic zones, soil types, and NPK ratios.
  Follow fertilisation recommendations for optimal yield and nutrient balance.
  Tailor recommendations based on local conditions and cropping patterns.
`;

  return (
    <div>
      <label>NPK Ratio:</label>
      <select value={selectedRatio} onChange={handleRatioChange}>
        <option value="4:2:1">4:2:1</option>
        <option value="4:2:2">4:2:2</option>
        <option value="6:2:2">6:2:2</option>
        {/* Add more options as needed */}
      </select>
      {nutrientSummary.length > 0 ? (<>
        <div style={{ padding: '20px' }}> 
        <Bar data={data} options={options} />
        </div>
        <div>
          <h6>Agroclimatic Zone-Based Recommendations:</h6>
          <ul>
            <li><strong>Western Himalayas (J & K, H.P.):</strong> Opt for Rice (K) with NPK ratio 4:2:1, fertilise with 120-60-30 kg/ha. Alternatively, consider Wheat with a ratio of 6:2:1 and 150-50-25 kg/ha.</li>
            <li><strong>Eastern Himalaya (Assam, W.B., NE states):</strong> Choose Rice (K) at 4:2:2 with 80-40-40 kg/ha, or Rice (R) with the same ratio.</li>
            <li><strong>Lower Gangetic Plain (W.B.):</strong> Rice (K) with 4:2:2 and 80-40-40 kg/ha, or Wheat with 6:2:2 and 120-40-40 kg/ha.</li>
            {/* Add more bullet points as needed */}
          </ul>
        </div>
        <br/>
        <div>
          <h6>Middle Gangetic Plain (Eastern U.P.):</h6>
          <ul>
            <li><strong>Rice (K):</strong> Opt for 4:2:2 ratio with 120-60-60 kg/ha.</li>
            <li><strong>Wheat:</strong> Choose 4:2:1.3 ratio with 120-60-40 kg/ha.</li>
            <li><strong>Sugarcane:</strong> Opt for 4.5:1.5:1 ratio with 180-60-40 kg/ha.</li>
          </ul>
        </div>
        <div>
        <h6>Bihar Recommendations:</h6>
          <ul>
            <li><strong>Rice (K):</strong> Opt for 6:2:1 ratio.</li>
            <li><strong>Wheat:</strong> Choose 6:3:1 ratio.</li>
            <li><strong>Sugarcane:</strong> Opt for 5:2.8:2 ratio.</li>
          </ul>
          <h6>Upper Gangetic Plain (Western U.P.):</h6>
          <ul>
            <li><strong>Rice (K):</strong> Opt for 5:2:2 ratio with 150-60-60 kg/ha.</li>
            <li><strong>Wheat:</strong> Choose 5:2:2 ratio with 150-60-60 kg/ha.</li>
            <li><strong>Sugarcane:</strong> Opt for 5:2:2 ratio with 150-60-60 kg/ha.</li>
            <li><strong>Potato:</strong> Opt for 6:2:3 ratio with 180-60-90 kg/ha.</li>
          </ul>
          <h6>Trans Gangetic Plain (Punjab):</h6>
          <ul>
            <li><strong>Rice (K):</strong> Opt for 4:1:1 ratio with 120-30-30 kg/ha.</li>
            <li><strong>Wheat:</strong> Choose 4:2:1 ratio with 120-60-30 kg/ha.</li>
            <li><strong>Sugarcane:</strong> Opt for 5:2:2 ratio with 150-60-60 kg/ha.</li>
            <li><strong>Cotton (Hybrid/Bt):</strong> Opt for 3:1:0 ratio with 150-50-0 kg/ha.</li>
            <li><strong>Gossypium hirsutum:</strong> Opt for 2.5:1:0 ratio.</li>
          </ul>
          <h6>Eastern Plateau & Hills:</h6>
          <ul>
            <li><strong>Chhattisgarh:</strong> Opt for Rice (K) with 4:2:1-3 ratio.</li>
            <li><strong>Pulses (K):</strong> Choose 1:3:1 ratio.</li>
          </ul>
          <h6>Jharkhand:</h6>
          <ul>
            <li><strong>Maize (K):</strong> Choose 4:2:1 ratio.</li>
            <li><strong>Wheat:</strong> Opt for 5:2:1 ratio.</li>
          </ul>
          <h6>Eastern Maharashtra (Bhandara region):</h6>
          <ul>
            <li><strong>Rice:</strong> Opt for 4:2:1.3 ratio.</li>
          </ul>
          <h6>Central Plateau & Hills (Vidarbha, Central M.P., N.E. Maharashtra, S. Gujarat, N.E. Karnataka, Northern Andhra Pradesh):</h6>
          <ul>
            <li><strong>Cotton:</strong> Opt for 2.5:1:0 ratio with 150-60-0 kg/ha.</li>
            <li><strong>Maize:</strong> Choose 4:2:1 ratio.</li>
          </ul>
          <h6>Overall Recommendations:</h6>
          <ul>
            <li>Consider specific crops based on agroclimatic zones, soil types, and NPK ratios.</li>
            <li>Follow fertilization recommendations for optimal yield and nutrient balance.</li>
            <li>Tailor recommendations based on local conditions and cropping patterns.</li>
          </ul>
        </div>
          </>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default DeficiencyGraph;
