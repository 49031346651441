import React from "react";
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

import "../../assets/range-date-picker.css";
import { Container, Row, Col } from "shards-react";
import UsersOverview from "../blog/UsersOverview";
import UsersByDevice from "../blog/UsersByDevice";
import PhGraph from "../blog/PhGraph";
import ConductivityGraph from "../blog/Conductivity";

class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);
        // Get the current date
        const currentDate = new Date();

        // Calculate the date 1 week before the current date
        const oneWeekBefore = new Date();
        oneWeekBefore.setDate(currentDate.getDate() - 7);
        // Set state with the calculated dates
        this.state = {
          startDate: oneWeekBefore,
          endDate: currentDate 
        };


    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleStartDateChange(value) {
    
    this.setState(prevState => ({
      ...prevState,
      startDate: new Date(value)
    }));
  }

  handleEndDateChange(value) {

    this.setState(prevState => ({
      ...prevState,
      endDate: new Date(value)
    }));
  }

  render() {
    const { className } = this.props;
    const classes = classNames(className, "d-flex", "my-auto", "date-range");

    return (
      <div >
        <InputGroup className={classes}>
          <DatePicker
            size="sm"
            selected={this.state.startDate}
            onChange={this.handleStartDateChange}
            placeholderText="Start Date"
            dropdownMode="select"
            className="text-center"
          />
          <DatePicker
            size="sm"
            selected={this.state.endDate}
            onChange={this.handleEndDateChange}
            placeholderText="End Date"
            dropdownMode="select"
            className="text-center"
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <i className="material-icons">&#xE916;</i>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <br />
        <Row>
          <Col lg="12" md="12" sm="12" className="mb-4">
            <UsersOverview
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
          </Col>
          <Col lg="12" md="6" sm="12" className="mb-4">
            <UsersByDevice
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
           
          </Col>
        </Row>
      </div>
    );
  }
}

export default RangeDatePicker;
