import React from "react"
import { Container } from "shards-react"

const Reports = () => {

   return (
    <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
   <iframe title="Crop recommendation model" width="100%" height="790" src="https://app.powerbi.com/view?r=eyJrIjoiMTg0OGVkMDktYjBiNy00ZTk2LWJiNmItZDA4N2E1NmU1YTQ5IiwidCI6ImIwZmQ2OWY1LTA3NjktNGUwNC1hMDQ1LWM2ZWM1NzVjMjM2OCJ9" frameborder="0" allowFullScreen="true"></iframe> 
      </Container>
   )
   
   
   
}

export default Reports