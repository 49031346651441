import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Dahboard"; 
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/CropRecommendation";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/FertilizerRecommendation";
import Tables from "./views/Tables";
import BlogPosts from "./views/Analysis";
import Visualisation from "./views/Visualisation";

import Test from "./views/Test";
import soyaAnalysis from "./views/soyaAnalysis";
import management_practices from "./views/managementPractice";
import weatherPrediction from "./views/weatherPrediction";
import Reports from "./views/Reports";
import DiseaseDetection from "./views/DiseaseDetection";
import SeedDetection from "./views/SeedDetection";
import YieldPrediction from "./views/YieldPrediction";
import SoilTestAnalyzer from "./views/SoilTestAnalyzer";
import soilTypeDetector from "./views/soilTypeDetector";
import soilDataAnalysis from "./views/soilDataAnalysis";
import NutrientCalculator from "./views/NutrientCalculator";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: Test
  },
  // {
  //   path: "/user-profile-lite",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  {
    path: "/crop-recommendation",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/agri/soil/data/analysis",
    layout: DefaultLayout,
    component: soilDataAnalysis
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/fertilizer-recommendation",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: BlogOverview
  // },
  {
    path: "/analysis",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/visualisation",
    layout: DefaultLayout,
    component: Visualisation
  },
    {
      path: "/soya",
      layout: DefaultLayout,
      component: soyaAnalysis
    },
    {
      path: "/management_practices",
      layout: DefaultLayout,
      component: management_practices
    },
    {
      path: "/weather-prediction",
      layout: DefaultLayout,
      component: weatherPrediction
    },
    {
      path: "/soil/type/detector",
      layout: DefaultLayout,
      component: soilTypeDetector
    },
    {
      path: "/reports",
      layout: DefaultLayout,
      component: Reports
    },
    {
      path: "/disease-detection",
      layout: DefaultLayout,
      component: DiseaseDetection
    },
    {
      path: "/seeds/detection",
      layout: DefaultLayout,
      component: SeedDetection
    },
    {
      path: "/yield-prediction",
      layout: DefaultLayout,
      component: YieldPrediction
    },
    {
      path: "/soil/test/analyzer",
      layout: DefaultLayout,
      component: SoilTestAnalyzer
    },
    {
      path: "/soil/nutrient/calculator",
      layout: DefaultLayout,
      component: NutrientCalculator
    },
];
