import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import DeficiencyGraph from "../components/components-overview/DeficiencyGraph";
import RecommendationForm from "../components/components-overview/FertilizerRecommendations";

const ComponentsOverview = () => (
  <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: "white" }}>
     <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Fertilizer Recommendation" subtitle="" className="text-sm-left" />
    </Row>
    <Container fluid className="px-2 h-500">
      <RecommendationForm/>
    </Container>
    {/* <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Standard NPK(4:2:1)" subtitle="" className="text-sm-left" />
    </Row>
    <Container fluid className="px-2 h-500">
      <DeficiencyGraph/>
    </Container> */}
  </Container>
);

export default ComponentsOverview;
