import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "shards-react";
import Chart from "../../utils/chart";
import { fetchData } from "../../api/AgriApi";

class UsersOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      error: null,
      loading: false
    };

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.fetchDataFromAPI();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.renderChart();
    }
  }

  fetchDataFromAPI = async () => {

    
    try {
      this.setState({ loading: true });

      const response = await fetchData();

      const potassiumValues = response.data.map(item => item.conductivity);


      console.log(potassiumValues, "condfValues" );
      
      const istTimes = response.data.map(timeString => {
        const utcTime = new Date(timeString);
        const istTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
        return istTime;
      });

      const updatedChartData = {
        labels: response.data.map(item => item.timestamp.slice(0, 19)),
        datasets: [
          {
            ...this.props.chartData.datasets[0],
            data: potassiumValues
          }
        ]
      };

      this.setState({ data: updatedChartData, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  renderChart = () => {
    const { data } = this.state;
    const { chartOptions } = this.props;

    const chartOptionsWithDefaults = {
      responsive: true,
      legend: {
        position: "top"
      },
      elements: {
        line: {
          tension: 0.3
        },
        point: {
          radius: 0
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: false,
            ticks: {
              callback(tick, index) {
                return index % 7 !== 0 ? "" : tick;
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              suggestedMax: 8,
              callback(tick) {
                if (tick === 0) {
                  return tick;
                }
                return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
              }
            }
          }
        ]
      },
      hover: {
        mode: "nearest",
        intersect: false
      },
      tooltips: {
        custom: false,
        mode: "nearest",
        intersect: false
      },
      ...chartOptions
    };

    const chart = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: data,
      options: chartOptionsWithDefaults
    });

    const buoMeta = chart.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[data.datasets[0].data.length - 1]._model.radius = 0;

    chart.render();
  };

  render() {
    const { title } = this.props;

    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          {this.state.loading ? (
            <div>Loading chart data...</div>
          ) : this.state.error ? (
            <div>Error: {this.state.error}</div>
          ) : (
            <canvas
              height="120"
              ref={this.canvasRef}
              style={{ maxWidth: "100% !important" }}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

UsersOverview.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.object,
  chartOptions: PropTypes.object
};

UsersOverview.defaultProps = {
  title: "Conductivity",
  chartData: {
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "conductivity",
        fill: "start",
        data: [],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      }
    ]
  },
  chartOptions: {}
};

export default UsersOverview;
