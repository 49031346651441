import React, { Component } from 'react';
import axios from 'axios';

class RecommendationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      N: 3,
      P: 4,
      K: 9,
      temperature: 10.0,
      humidity: 13.5,
      ph: 7.27,
      rainfall: 50,
      prediction: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/ai/recommend_crop`,
        this.state
      );

      this.setState({ prediction: response.data.prediction });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { N, P, K, temperature, humidity, ph, rainfall, prediction } =
      this.state;

    return (
      <div style={styles.container}>
        <form onSubmit={this.handleSubmit} style={styles.form}>
          <label >
            Nitrogen(N):
            <input
              type="number"
              name="N"
              value={N}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}
            />
          </label>
          <br />
          <label>
            Phosphorus(P):
            <input
              type="number"
              name="P"
              value={P}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Potassium(K):
            <input
              type="number"
              name="K"
              value={K}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Temperature:
            <input
              type="number"
              name="temperature"
              value={temperature}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Humidity:
            <input
              type="number"
              name="humidity"
              value={humidity}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            pH:
            <input
              type="number"
              name="ph"
              value={ph}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <label>
            Rainfall:
            <input
              type="number"
              name="rainfall"
              value={rainfall}
              onChange={this.handleInputChange}
              style={{ marginLeft: 4}}

            />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>
        {prediction && (
          <div style={styles.recommendation}>
            <h6>Recommendation:</h6>
            <p>{prediction}</p>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  recommendation: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f2f2f2',
    borderRadius: '4px',
  },
};

export default RecommendationForm;
