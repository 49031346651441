import { Button, Typography } from "@mui/material";
import React from "react";
import Plot from "react-plotly.js";
import { Col, Row } from "shards-react";

const SoyabeanDiseaseDetection = ({predictDisease, imagePreview,handleChange}) => {
    return (
        <>
                                <Button
                variant="contained"
                component="label"

            >
                Upload
                <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleChange}
                />
            </Button>
                      <Row noGutters className="py-3" style={{ maxWidth: '20%' }}>
                {imagePreview && (
                    <div>
                        <img src={imagePreview} alt="Selected" style={{ marginTop: '10px', maxWidth: '50%' }} />
                    </div>
                )}
            </Row>
            {
                predictDisease && (
                    <>

                        <Row noGutters>
                            <Typography>
                                Prediction: {predictDisease.predictions}
                            </Typography>
                        </Row>
                        <Row noGutters>
                            <Typography>

                                Confidence:: {predictDisease.confidence}
                            </Typography>
                        </Row>
                        <Row noGutters>
                            Prediction Probabilities:
                        </Row>
                        <Row noGutters >
                            <Col>

                                {
                                    predictDisease.prediction_probabilities ? predictDisease.prediction_probabilities.map((data, index) => {
                                        return (
                                            <Typography key={index}>
                                                <span>
                                                    {data.class_name}:{data.formatted_probability}
                                                </span>
                                            </Typography>
                                        )
                                    }) : ""
                                }
                            </Col>
                        </Row>
                        <Row noGutters className="py-4">
                            <Plot
                                data={[
                                    {
                                        type: 'bar',
                                        x: predictDisease.class_names,
                                        y: predictDisease.prediction[0].map(p => p * 100),  // Convert to percentage
                                    },
                                ]}
                                layout={{
                                    title: 'Prediction Probabilities',
                                    yaxis: {
                                        title: 'Probability (%)',
                                    },
                                }}
                            />
                        </Row>
                    </>

                )
            }

        </>
    )
}


export default SoyabeanDiseaseDetection