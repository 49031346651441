import { Button } from "@mui/material"
import axios from "axios";
import React, { useState } from "react"
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const SoilTestAnalyzer = () => {
    const [imagePreview, setImagePreview] = useState(null);
    const [analysis, setImageAnalysis] = useState(null);
  
    const handleChange = async (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }


            try {

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/get/soil/test/analysis`, formData, {
                    'Content-Type': 'multipart/form-data'
                }
                );
                if (response.data.status == 'success') {
                    setImageAnalysis(response.data)
                }

            } catch (error) {
                console.error('Error uploading file:', error);
            }



    }
    let lines = [];
    if(analysis ) 
    lines = analysis.extracted.split(/\n+/);
    
    console.log(lines);
    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
                 <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Soil Analysis and Fertilizer Recommendation System" className="text-sm-left" />
      </Row>
            <Row noGutters className="page-header py-2">
        <Button
        variant="contained"
        component="label"
    >
        Upload
        <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleChange}
        />
    </Button>

    </Row>
    <Row noGutters className="py-3" style={{ width: '50%' }}>
                {imagePreview && (
                    <div>
                        <img src={imagePreview} alt="Selected" style={{ marginTop: '10px', maxWidth: '50%' }} />
                    </div>
                )}
            </Row>
    {/* <Row noGutters className="py-3" style={{ width: '50%' }}> */}
    {lines.map((line, index) => (
        <Row noGutters key={index}>{line}</Row>
      ))}
            {/* </Row> */}
   </Container>
    )
}

export default SoilTestAnalyzer