import { Box, Button, Grid, InputLabel, FormControl, MenuItem, Select, Input, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Plot from "react-plotly.js";

const NutrientCalculator = () => {
    const [data, setLatest] = useState({})
    const [zone, setZone] = useState("Western Himalayas (J & K, H.P.)")
    const [soil, setSoil] = useState()
    const [crop, setCrop] = useState()
    const [input, setInput] = useState("1.0")
    const [npk, setNpk] = useState()
    const [predict, setPredict] = useState()
    const [currsoil, setCurrSoil] = useState()
    const [currcrop, setCurrCrop] = useState()
    const [currnpk, setCurrNpk] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/soil/nutrient/calculator`);
                if (response.data.status == 'success') {
                    console.log(response.data);

                    setLatest(response.data);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/zone/soil/nutrient/calculator?zone=${encodeURIComponent(zone)}`);
                if (response.data.status == 'success') {
                    setSoil(response.data);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [zone]);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/zone/crop/nutrient/calculator?zone=${encodeURIComponent(zone)}&soil=${encodeURIComponent(currsoil)}`);
                if (response.data.status == 'success') {
                    setCrop(response.data);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [currsoil]);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/zone/npk/nutrient/calculator?zone=${encodeURIComponent(zone)}&soil=${encodeURIComponent(currsoil)}&crop=${encodeURIComponent(currcrop)}`);
                if (response.data.status == 'success') {
                    setNpk(response.data);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [currcrop]);

    const handlePredict = async () => {
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/zone/predict/nutrient/calculator?zone=${encodeURIComponent(zone)}&soil=${encodeURIComponent(currsoil)}&crop=${encodeURIComponent(currcrop)}&npk=${encodeURIComponent(currnpk)}&hectares=${encodeURIComponent(input)}`);
            if (response.data.status == 'success') {
                setPredict(response.data)
            }
        }

        catch (err) {
            console.log(err);

        }
    }

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 90,
            headerAlign: "center",
            align: "center",
            renderHeader: () => <strong style={{ color: "#334474" }}>{"SL. No"}</strong>,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            field: "humidity",
            headerName: "Humidity",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#334474" }}>{"Humidity"}</strong>
            ),
        },
        {
            field: "ph",
            headerName: "ph",
            width: 80,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#334474" }}>{"pH"}</strong>
            ),
        },
        {
            field: "potassium",
            headerName: "Potassium",
            width: 220,
            headerAlign: "center",
            align: "center",
            renderHeader: () => <strong style={{ color: "#334474" }}>{"Potassium"}</strong>,
        },
        {
            field: "temperature",
            headerName: "temperature",
            width: 220,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#334474" }}>{"Temperature"}</strong>
            ),
        },
        {
            field: "timestamp",
            headerName: "timestamp",
            width: 220,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#57926e" }}>{"Timestamp"}</strong>
            ),
        },
        {
            field: "conductivity",
            headerName: "conductivity",
            width: 220,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#57926e" }}>{"Conductivity"}</strong>
            ),
        },
        {
            field: "nitrogen",
            headerName: "nitrogen",
            width: 220,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#57926e" }}>{"Nitrogen"}</strong>
            ),
        },
        {
            field: "phosphorus",
            headerName: "phosphorus",
            width: 220,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#57926e" }}>{"Phosphorus"}</strong>
            ),
        },
    ];

    const columnsNutrientTable = [

        {
            field: "id",
            headerName: "ID",
            width: 90,
            headerAlign: "center",
            align: "center",
            renderHeader: () => <strong style={{ color: "#334474" }}>{"SL. No"}</strong>,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            field: "Nutrient",
            headerName: "Nutrient",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#334474" }}>{"Nutrient"}</strong>
            ),
        },
        {
            field: "Current",
            headerName: "Current",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#334474" }}>{"Current"}</strong>
            ),
        },
        {
            field: "Recommended",
            headerName: "Recommended",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderHeader: () => <strong style={{ color: "#334474" }}>{"Recommended"}</strong>,
        },
        {
            field: "Required",
            headerName: "Required",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#334474" }}>{"Required"}</strong>
            ),
        },
        {
            field: "Excess",
            headerName: "Excess",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <strong style={{ color: "#57926e" }}>{"Excess"}</strong>
            ),
        },

    ];

    const islatestDataReady = data && data.datass && Array.isArray(data.datass);

    const isagroclimaticzonesReady = data && data.agroclimatic_zones && Array.isArray(data.agroclimatic_zones);
    const isSoilReady = soil && soil.soils && Array.isArray(soil.soils);
    const isPredictReady = predict && predict.recommended_nutrients && predict.required_nutrients && predict.excess_nutrients && Array.isArray(predict.recommended_nutrients) && Array.isArray(predict.excess_nutrients) && Array.isArray(predict.required_nutrients) && predict.current_values && Array.isArray(predict.current_values) && predict.total_required_nutrients && Array.isArray(predict.total_required_nutrients) && predict.total_excess_nutrients && Array.isArray(predict.total_excess_nutrients) && predict.recommendations && Array.isArray(predict.recommendations);
    let current_values
    let recommended_nutrients
    if (isPredictReady) {
        current_values = predict.current_values
        recommended_nutrients = predict.recommended_nutrients
    }

    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Nutrient Calculator" className="text-sm-left" />
            </Row>
            {
                islatestDataReady ? <Row noGutters className="page-header py-2">

                    <DataGrid
                        getRowId={(data) => data.ph + data.timestamp}
                        rows={data.datass}
                        columns={columns}
                        density="compact"
                        rowCount={100}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }}
                    />
                </Row> : <Row noGutters className="page-header py-2">loading latestData...</Row>
            }

            <Row noGutters className="page-header py-2 px-2">
                <Col>
                    {
                        isagroclimaticzonesReady &&
                        <Box sx={{ maxWidth: 650, marginRight: 10 }}>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth={true}
                                sx={{ gap: 6 }}
                            >

                                <InputLabel id="asset-select-label" >Agroclimatic Zone</InputLabel>

                                <Select
                                    labelId="asset-select-label"
                                    id="AgroclimaticZone"
                                    value={zone}
                                    onChange={(e) => setZone(e.target.value)}
                                >
                                    {data.agroclimatic_zones.map((zone, index) =>
                                        <MenuItem index={index} value={zone} name={zone}>{zone}</MenuItem>

                                    )}
                                </Select>


                            </FormControl>
                        </Box>
                    }
                </Col>
                <Col>
                    {
                        isagroclimaticzonesReady &&
                        <Box sx={{ maxWidth: 650, marginRight: 10 }}>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth={true}
                                sx={{ gap: 6 }}
                            >

                                <InputLabel id="asset-select-label" >Select Soil</InputLabel>

                                <Select
                                    labelId="asset-select-label"
                                    id="Select Soil"
                                    value={currsoil}
                                    onChange={(e) => setCurrSoil(e.target.value)}
                                >
                                    {soil.soils.map((soil, index) =>
                                        <MenuItem index={index} value={soil} name={soil}>{soil}</MenuItem>

                                    )}
                                </Select>


                            </FormControl>
                        </Box>
                    }
                </Col>
                <Col>
                    {
                        isagroclimaticzonesReady &&
                        <Box sx={{ maxWidth: 650, marginRight: 10 }}>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth={true}
                                sx={{ gap: 6 }}
                            >

                                <InputLabel id="asset-select-label" >Select Crop/Cropping System</InputLabel>

                                <Select
                                    labelId="asset-select-label"
                                    id="Select Crop/Cropping System"
                                    value={currcrop}
                                    onChange={(e) => setCurrCrop(e.target.value)}
                                >
                                    {crop.crops.map((crop, index) =>
                                        <MenuItem index={index} value={crop} name={crop}>{crop}</MenuItem>

                                    )}
                                </Select>


                            </FormControl>
                        </Box>
                    }
                </Col>
                <Col>
                    {
                        isagroclimaticzonesReady &&
                        <Box sx={{ maxWidth: 650 }}>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth={true}
                                sx={{ gap: 6 }}
                            >

                                <InputLabel id="asset-select-label" >Select NPK Ratio</InputLabel>

                                <Select
                                    labelId="asset-select-label"
                                    id="Select NPK Ratio"
                                    value={currnpk}
                                    onChange={(e) => setCurrNpk(e.target.value)}
                                >
                                    {npk.npk_ratios.map((npk, index) =>
                                        <MenuItem index={index} value={npk} name={npk}>{npk}</MenuItem>

                                    )}
                                </Select>


                            </FormControl>
                        </Box>
                    }
                </Col>

            </Row>
            <Row noGutters className="py-2 px-2">
                <Box sx={{ maxWidth: 650 }}>
                    <FormControl
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        sx={{ gap: 6 }}
                    >
                        <InputLabel id="asset-select-label" >Enter Hectares of Land</InputLabel>
                        <Input labelId="asset-select-label" step="any" placeholder="Enter Hectares of Land" value={input} onChange={(e) => setInput(e.target.value)} />
                    </FormControl>
                </Box>
            </Row>
            {
                currnpk && <Row noGutters className="page-header py-2 px-2">
                    <Button onClick={handlePredict}> Calculate Nutrients</Button>
                </Row>
            }
            {
                isPredictReady && <Row noGutters className="py-2 px-2">
                    Nutrient Calculation
                </Row>
            }

            {
                isPredictReady && <>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Col>Current Nutrients:</Col>
                        <Col>Nitrogen: {predict.current_values[0]}</Col>
                        <Col>Phosphorus: {predict.current_values[1]}</Col>
                        <Col> Potassium: {predict.current_values[2]}</Col>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Col>Recommended Nutrients:</Col>
                        <Col>Nitrogen: {predict.recommended_nutrients[0]}</Col>
                        <Col>Phosphorus: {predict.recommended_nutrients[1]}</Col>
                        <Col> Potassium:{predict.recommended_nutrients[2]}</Col>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Col>Required Nutrients:</Col>
                        <Col>Nitrogen: {predict.required_nutrients[0]}</Col>
                        <Col>Phosphorus: {predict.required_nutrients[1]}</Col>
                        <Col> Potassium: {predict.required_nutrients[2]}</Col>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Col>Excess Nutrients:</Col>
                        <Col>Nitrogen: {predict.excess_nutrients[0]}</Col>
                        <Col>Phosphorus: {predict.excess_nutrients[1]}</Col>
                        <Col> Potassium: {predict.excess_nutrients[2]}</Col>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Plot
                            data={[
                                {
                                    x: ['Nitrogen', 'Phosphorus', 'Potassium'],
                                    y: current_values,
                                    type: 'bar',
                                    name: 'Current',
                                    marker: { color: 'blue' },
                                },
                                {
                                    x: ['Nitrogen', 'Phosphorus', 'Potassium'],
                                    y: recommended_nutrients,
                                    type: 'bar',
                                    name: 'Recommended',
                                    marker: { color: 'orange' },
                                },
                            ]}
                            layout={{
                                barmode: 'group',
                                title: 'Nutrient Comparison',
                                yaxis: { title: 'Values' },
                                xaxis: { title: 'Nutrients' },
                            }}
                        />
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        Nutrients Tables

                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <DataGrid
                            getRowId={(data) => data.Nutrient + data.Current}
                            rows={predict.nutrients_table}
                            columns={columnsNutrientTable}
                            density="compact"
                            rowCount={100}
                            paginationMode="server"
                            components={{ Toolbar: GridToolbar }}
                        />

                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        Nutrients Required For Land
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>
                            Hectares of Land: {input}
                        </Typography>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        Total Required Nutrients (for {input} hectares)
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>
                            Nitrogen: {predict.total_required_nutrients[0]}kg
                        </Typography>

                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>
                            Phosphorus: {predict.total_required_nutrients[1]}kg
                        </Typography>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>
                            Potassium: {predict.total_required_nutrients[2]}kg
                        </Typography>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        Total Excess Nutrients (for {input} hectares)
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>
                            Nitrogen: {predict.total_excess_nutrients[0]}kg

                        </Typography>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>

                            Phosphorus: {predict.total_excess_nutrients[1]}kg

                        </Typography>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        <Typography>
                            Potassium: {predict.total_excess_nutrients[2]}kg


                        </Typography>
                    </Row>
                    <Row noGutters className="py-2 px-2" style={{ width: '50%' }}>
                        Recommendations
                    </Row>
                    {
                        predict.recommendations.map((recommend, index) => {
                            return (
                                <Row index={index} noGutters className="py-2 px-2" style={{ width: '50%' }}>
                                    <Typography>{recommend}</Typography>
                                </Row>
                            )
                        })
                    }


                </>
            }




        </Container>
    )
}

export default NutrientCalculator