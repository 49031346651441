import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "shards-react"
import Plot from "react-plotly.js";

const YieldPrediction = () => {
    const [crop, setSelectedCrop] = useState("Soyabean");
    const [states, setStates] = useState({});
    const [currentstate, setCurrentState] = useState("");
    const [district, setDistrict] = useState({});
    const [currentdistrict, setCurrentdistrict] = useState("");
    const [yieldPrediction, setYieldPrediction] = useState("");

    let crops = [
        {
            id: 1,
            name: "Soyabean"
        },
    ];

    const handleInputChange = e => {
        setSelectedCrop(e.target.value);

    };
    const handleInputChangeState = e => {
        setCurrentState(e.target.value);

    };
    const handleInputChangeDistrict = e => {
        setCurrentdistrict(e.target.value);

    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/get/soyabean/yield/prediction/state`, {
                "Crop": crop
            }
            );
            if (response.data.status == "success") {
                setStates(response.data.crop_type_state_data);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/get/soyabean/yield/prediction/state/district`, {
                "Crop": crop,
                "State": currentstate
            }
            );
            if (response.data.status == "success") {
                setDistrict(response.data.crop_type_district_data);
            }
        };

        fetchData();
    }, [currentstate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/get/soyabean/yield/prediction`, {
                    "Crop": crop,
                    "State": currentstate,
                    "District": currentdistrict
                }
                );
                if (response.data.status == "success") {
                    setYieldPrediction(response.data);
                }

            } catch (err) {
                setYieldPrediction('')
            }


        };

        fetchData();


    }, [currentdistrict]);

    return (
        <Container fluid className="main-content-container px-4 py-4" style={{ backgroundColor: "white" }}>
            <Row noGutters className="page-header py-2">
                {/* <PageTitle sm="4" title="SoyaBean" subtitle="" className="text-sm-left" /> */}
                <Col>
                    <FormControl style={{ marginTop: "12px" }}>
                        <InputLabel id="asset-select-label">Crop Type</InputLabel>

                        <Select
                            labelId="asset-select-label"
                            id="asset-select"
                            name="cropType"
                            value={crop}
                            onChange={e => handleInputChange(e)}
                            label="Select category"
                            style={{ width: 165 }}
                        >
                            {crops.map(crop => {
                                return (
                                    <MenuItem key={crop.id} value={crop.name}>
                                        {crop.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Col>
            </Row>

            <Row noGutters className="py-2">
                {/* <PageTitle sm="4" title="SoyaBean" subtitle="" className="text-sm-left" /> */}
                <Col>
                    <FormControl style={{ marginTop: "12px" }}>
                        <InputLabel id="asset-select-label">State</InputLabel>

                        <Select
                            labelId="asset-select-label"
                            id="asset-select"
                            name="stateType"
                            value={currentstate}
                            onChange={e => handleInputChangeState(e)}
                            label="Select State"
                            style={{ width: 165 }}
                        >
                            {Object.values(states).map(state => {
                                return (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Col>
            </Row>

            {
                currentstate && <Row noGutters className="py-2">
                    {/* <PageTitle sm="4" title="SoyaBean" subtitle="" className="text-sm-left" /> */}
                    <Col>
                        <FormControl style={{ marginTop: "12px" }}>
                            <InputLabel id="asset-select-label">Select District</InputLabel>

                            <Select
                                labelId="asset-select-label"
                                id="asset-select"
                                name="districtType"
                                value={currentdistrict}
                                onChange={e => handleInputChangeDistrict(e)}
                                label="Select District"
                                style={{ width: 165 }}
                            >
                                {Object.values(district).map(district => {
                                    return (
                                        <MenuItem key={district} value={district}>
                                            {district}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Col>
                </Row>

            }

            {
                yieldPrediction && yieldPrediction.historical_data && <Row noGutters>

                    <Plot
                        data={[{
                            x: yieldPrediction.historical_data.map(data => data.Crop_Year),
                            y: yieldPrediction.historical_data.map(data => data.Production),
                            mode: 'lines',
                            type: 'scatter',
                            name: 'Historical'
                        }, {
                            x: yieldPrediction.future_data && Object.values(yieldPrediction.future_data.Crop_Year || {}),
                            y: yieldPrediction.future_data && Object.values(yieldPrediction.future_data.Production || {}),
                            mode: 'lines',
                            type: 'scatter',
                            name: 'Future',
                            line: { dash: 'dash' }
                        }]}
                        layout={{
                            title: `Soybean Production Trend in ${currentstate}, ${currentdistrict}`,
                            xaxis: { title: 'Year' },
                            yaxis: { title: 'Production (1000 tons)' }
                        }}
                        style={{ width: '100%', height: '100%' }}
                    />
                </Row>
            }
            {
                yieldPrediction == '' && <Row noGutters className="py-2">
                    No Data available for yield prediction trends
                </Row>
            }


        </Container>
    )
}

export default YieldPrediction