import axios from 'axios';

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
} 

export const fetchData = async (startDate, endDate) => {
    try {
       startDate = formatDate(startDate)
       endDate = formatDate(endDate)
       
       let response;
       if(startDate){
       response = await axios.get(`${process.env.REACT_APP_BASE_URL}/agri/data?startOffset=${startDate}&endOffset=${endDate}`);
           console.log(response,9);
           
       }
       else {
       response = await axios.get(`${process.env.REACT_APP_BASE_URL}/agri/data`);

       }
      console.log(response, "response");
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from the API');
    }
  };

