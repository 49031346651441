import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
// import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
import PhGraph from "./../components/blog/PhGraph";
import ConductivityGraph from "./../components/blog/Conductivity";
// import NewDraft from "./../components/blog/NewDraft";
// import Discussions from "./../components/blog/Discussions";
// import TopReferrals from "./../components/common/TopReferrals";

// import * as mqtt from "mqtt";
// import { set } from "lodash";

const Dashboard = ()=> {
  
  const [temp, setTemp] = useState(0);
  
  const [Humidity, setHumidity] = useState(0);
  const [conductivity, setConductivity] = useState(0);
  const [ph, setPh] = useState(0);
  const [nitrogen, setNitrogen] = useState(0);
  const [phosphorus, setPhosphorus] = useState(0);
  const [pottasium, setPottasium] = useState(0);

  // const [prevTemp, setPrevTemp] = useState(prevState => {
  //   localStorage.getItem("temp");
  // });
  // const [percent, setPercent] = useState(0);

  // const clientId =
  //   "mqttjs_" +
  //   Math.random()
  //     .toString(16)
  //     .substr(2, 8);

  // const host = "ws://mqtt.trackcoredata.com:9001/mqtt";

  // const options = {
  //   keepalive: 60,
  //   clientId: clientId,
  //   protocolId: "MQTT",
  //   protocolVersion: 4,
  //   clean: true,
  //   reconnectPeriod: 1000,
  //   connectTimeout: 30 * 1000,
  //   will: {
  //     topic: "WillMsg",
  //     payload: "Connection Closed abnormally..!",
  //     qos: 0,
  //     retain: false
  //   }
  // };

  // console.log("Connecting mqtt client");
  // const client = mqtt.connect(host, options);

  // client.on("error", err => {
  //   console.log("Connection error: ", err);
  //   client.end();
  // });

  // client.on("reconnect", () => {
  //   console.log("Reconnecting...");
  // });

  // client.on("connect", function() {
  //   client.subscribe("npk", function(err) {
  //     if (!err) {
  //       console.log("hi");
  //     }
  //   });
  // });

  // client.on("message", function(topic, message) {
  //   // message is Buffer

  //   const receivedData = message.toString();
  //   const parsed = JSON.parse(receivedData);
    
  //   setTemp(parsed.temp);
  //   setHumidity(parsed.hum);
  //   setConductivity(parsed.cond);
  //   setPh(parsed.ph);
  //   setNitrogen(parsed.nit);
  //   setPhosphorus(parsed.phos);
  //   setPottasium(parsed.pot);

  //   const previous = localStorage.getItem("temp");
  //   const current = parsed.temp;
  //   console.log("current", current);

  //   console.log("previouss", previous);

  //   localStorage.setItem("temp", parsed.temp);

  //   client.end();
  // });

  useEffect(() => {
    fetchApiData(); // Fetch API data when the component mounts
  }, []);

  const fetchApiData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/agri/latest/data`
      ); // Replace with your actual API endpoint
      const apiData = await response.json();
      const a = apiData.data[0];
      setTemp(a.temperature);
      setHumidity(a.humidity);
      setConductivity(a.conductivity);
      setPh(a.ph);
      setNitrogen(a.nitrogen);
      setPhosphorus(a.phosphorus);
      setPottasium(a.potassium);
    } catch (error) {
      console.log("Error fetching API data:", error);
    }
  };



//   const smallStat = [
//     {
//       label: "Temperature",
//       value: [temp],
//       percentage: "4.7%",
//       increase: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "6", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgba(0, 184, 216, 0.1)",
//           borderColor: "rgb(0, 184, 216)",
//           data: [1, 2, 1, 3, 5, 4, 7]
//         }
//       ]
//     },
//     {
//       label: "Humidity",
//       value: [Humidity],
//       percentage: "12.4",
//       increase: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "6", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgba(23,198,113,0.1)",
//           borderColor: "rgb(23,198,113)",
//           data: [1, 2, 3, 3, 3, 4, 4]
//         }
//       ]
//     },
//     {
//       label: "Conductivity",
//       value: [conductivity],
//       percentage: "3.8%",
//       increase: false,
//       decrease: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "4", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgba(255,180,0,0.1)",
//           borderColor: "rgb(255,180,0)",
//           data: [2, 3, 3, 3, 4, 3, 3]
//         }
//       ]
//     },
//     {
//       label: "pH",
//       value: [ph],
//       percentage: "2.71%",
//       increase: false,
//       decrease: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "4", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgba(255,65,105,0.1)",
//           borderColor: "rgb(255,65,105)",
//           data: [1, 7, 1, 3, 1, 4, 8]
//         }
//       ]
//     },
//     {
//       label: "Nitrogen",
//       value: [nitrogen],
//       percentage: "2.4%",
//       increase: false,
//       decrease: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "4", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgb(0,123,255,0.1)",
//           borderColor: "rgb(0,123,255)",
//           data: [3, 2, 3, 2, 4, 5, 4]
//         }
//       ]
//     },
//     {
//       label: "Phosphorus",
//       value: [phosphorus],
//       percentage: "12.4",
//       increase: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "6", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgba(23,198,113,0.1)",
//           borderColor: "rgb(23,198,113)",
//           data: [1, 2, 3, 3, 3, 4, 4]
//         }
//       ]
//     },
//     {
//       label: "Pottasium",
//       value: [pottasium],
//       percentage: "12.4",
//       increase: true,
//       chartLabels: [null, null, null, null, null, null, null],
//       attrs: { md: "6", sm: "6" },
//       datasets: [
//         {
//           label: "Today",
//           fill: "start",
//           borderWidth: 1.5,
//           backgroundColor: "rgba(23,198,113,0.1)",
//           borderColor: "rgb(23,198,113)",
//           data: [1, 2, 3, 3, 3, 4, 4]
//         }
//       ]
//     }
//   ];

  return (
    <>
      <Container fluid className="main-content-container px-4" style={{ backgroundColor: "white" }}>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/* <PageTitle
            title="Blog Overview"
            subtitle="Dashboard"
            className="text-sm-left mb-3"
          /> */}
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          {/* {smallStat.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))} */}
        </Row>
        <br />
       
        <br />
        <Row>
          {/* Users Overview */}
          <Col lg="12" md="12" sm="12" className="mb-4">
            <UsersOverview />
          </Col>

          {/* Users by Device */}
          <Col lg="12" md="6" sm="12" className="mb-4">
            <UsersByDevice />
          </Col>

          {/* New Draft */}
          {/* <Col lg="4" md="6" sm="12" className="mb-4">
            <NewDraft />
          </Col> */}

          {/* Discussions */}
          {/* <Col lg="5" md="12" sm="12" className="mb-4">
            <Discussions />
          </Col> */}

          {/* Top Referrals */}
          {/* <Col lg="3" md="12" sm="12" className="mb-4">
            <TopReferrals />
          </Col> */}
          <Col lg="12" md="12" sm="12" className="mb-4">
            <PhGraph />
          </Col>

          <Col lg="12" md="6" sm="12" className="mb-4">
            <ConductivityGraph />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Dashboard.propTypes = {
//   /**
//    * The small stats dataset.
//    */
//   smallStat: PropTypes.array
// };

export default Dashboard;
